import React from 'react'
import styles from './language-selector.module.css'

function LanguageSelector({ onChange, value }) {
  const currentLanguage = value
  return (
    <div className={styles.languageSelector}>
      <div className={styles.control}>
        <button
          id="language-selector"
          name="language-selector"
          onClick={
            () => {
              let language
              switch (currentLanguage) {
                case 'ca':
                  language = 'en'
                  break
                case 'en':
                  language = 'ca'
                  break
                default:
                  language = currentLanguage
              }
              if (language != currentLanguage) {
                onChange(language)
              }
            }
          }
          className={styles.button}
        >
          <span>
            { 
              currentLanguage === 'ca' ?
                (
                  'English'
                ) : (
                  'Català'
                )
            }
          </span>
          <svg className={styles.svg} width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.5585 3.98855C17.8754 3.98855 20.5643 6.67753 20.5643 9.99415C20.5643 13.311 17.8754 16 14.5585 16C11.7147 16 9.33279 14.023 8.71119 11.3687C7.89808 11.7797 6.97902 12.0115 6.0056 12.0115C2.68873 12.0115 0 9.32247 0 6.00585C0 2.68898 2.68873 0 6.0056 0C8.8496 0 11.2316 1.97704 11.8532 4.63129C12.666 4.22033 13.5853 3.98855 14.5585 3.98855ZM8.5997 5.95829L10.0442 5.12429V5.54104C10.9814 5.56671 12.1338 5.84378 12.2543 7.64743C11.7507 6.60279 10.8976 6.31741 10.0442 6.37529V6.79229L8.5997 5.95829ZM12.6101 8.33521L11.1654 9.16921V8.75221C10.2282 8.72654 9.07584 8.44972 8.9553 6.64607C9.45912 7.69071 10.312 7.97609 11.1654 7.91821V7.50121L12.6101 8.33521ZM7.62931 4.28576C7.57042 5.44138 7.14612 6.56781 6.32571 7.39803C6.86678 7.96955 7.46044 8.22171 8.22121 8.35484L8.05436 9.31039C7.0525 9.13297 6.26028 8.76681 5.56595 8.00302C5.04879 8.32162 4.55378 8.47413 3.95709 8.57857L3.79049 7.62377C4.2387 7.54525 4.59857 7.4466 4.99846 7.21331C4.55655 6.42688 4.39448 5.6261 4.37309 4.73019C4.69496 4.72189 5.01759 4.71459 5.33946 4.70653C5.35556 5.38224 5.45623 5.97716 5.76099 6.58467C6.32017 5.95074 6.60832 5.12328 6.66142 4.28576H3.68631C3.68631 3.95961 3.68631 3.63321 3.68631 3.30706H5.4142V2.70106C5.80855 2.70106 6.2029 2.70106 6.5975 2.70106V3.30706H8.32514C8.32514 3.63321 8.32514 3.95961 8.32514 4.28576H7.62931ZM13.9465 6.84514C14.3051 6.84514 14.6637 6.84514 15.0223 6.84514L17.5867 13.1432C17.177 13.1432 16.7678 13.1432 16.3581 13.1432L15.664 11.3252H13.3455L12.695 13.1432C12.3069 13.1432 11.9186 13.1432 11.5303 13.1432L13.9465 6.84514V6.84514ZM13.689 10.3812H15.3024C15.0213 9.64535 14.7306 8.9105 14.4805 8.16358C14.2696 8.9168 13.9701 9.64988 13.689 10.3812Z" fill="currentColor"/>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default LanguageSelector
